<template>
  <div>
    <div>
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="收货地址">
          {{ formInline.tworksite.wsName }}
        </a-form-model-item>

        <a-form-model-item label="供应商">
          <!-- <a-input v-model="formInline.ptName" placeholder=""> </a-input> -->
          <a-select
            v-model="formInline.cmSId"
            class="search-expand-value"
            @change="handleChange"
          >
            <a-select-option
              v-for="opt in GetSelectCompanies"
              :key="opt.msSId"
              :value="opt.msSId"
              >{{ opt.tsupplier.sname }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <div class="center">
            <a-button :style="{ marginRight: '8px' }" @click="onClose">
              取消
            </a-button>
            <a-button type="primary" html-type="submit"> 提交 </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {
  GetSelectByIdList,
  GetConstructionMaterialList,
} from "@/api/apiJF/material";
export default {
  name: "MaterialCategoriesEdit",
  components: {},
  props: {
    record: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {
        cmSId: "",
      },
      formInlineEdit: {},
      GetSelectCompanies: [],
    };
  },
  created() {
    let record = JSON.parse(this.record);
    this.formInline = JSON.parse(this.record);
    console.log(JSON.parse(this.record));
    this.GetSelectByIdList(record.cmMId);
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取可选择的供应商
    GetSelectByIdList(mId) {
      let queryParam = {
        mId: mId,
      };
      let orderParam = ["mId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetSelectByIdList(params).then((res) => {
        this.GetSelectCompanies = res.data.tmaterialSuppliers;
      });
    },
    handleChange() {
      this.$forceUpdate();
    },
    // 提交按钮
    handleSubmit(e) {
      this.updata();
    },
    updata() {
      let params = JSON.parse(this.record);
      //   cmEarlyOrderTime  应下单时间
      //   cmLateOrderTime
      //   cmEarlySendTime  应发货时间
      //   cmLateSendTime
      //   cmSChange 1 供应商是否变更
      //   cmSName   供应商名称
      //   cmSId   供应商id
      //   cmMPreTime  物料准备时长
      //   cmMdeLiTime  物料配送时长
      //   需要到达日期
      let cmEarlyReachTime = JSON.parse(this.record).cmEarlyReachTime;
      let cmLateReachTime = JSON.parse(this.record).cmLateReachTime;
      let cmSId = JSON.parse(this.record).cmSId;
      if (cmSId == this.formInline.cmSId) {
        this.$message.success("供应商未变更");
        this.onClose();
      } else {
        this.GetSelectCompanies.forEach((el) => {
          if (el.msSId == this.formInline.cmSId) {
            params.cmSChange = 1;
            params.cmSName = el.tsupplier.sname;
            params.cmSId = el.msSId;
            params.cmMPreTime = el.msPreTime;
            params.cmMdeLiTime = el.msDeliTime;
            params.cmEarlyOrderTime =
              cmEarlyReachTime - el.msPreTime * 3600 - el.msDeliTime * 3600;
            params.cmLateOrderTime =
              cmLateReachTime - el.msPreTime * 3600 - el.msDeliTime * 3600;
            params.cmEarlySendTime = cmEarlyReachTime - el.msDeliTime * 3600;
            params.cmLateSendTime = cmLateReachTime - el.msDeliTime * 3600;
          }
        });
        GetConstructionMaterialList(params).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
      this.filters.GetSelectCompanies = [];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  //   text-align: center;
}
.center {
  text-align: center;
}
</style>