<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="
            isShowBtns.indexOf(
              'WaitingOrder-/api/constructionMaterial/addOrder'
            ) > -1
          "
          type="primary"
          @click="GoToOrderMaterials(record, '确认下单物料', 'allBtn')"
          >下单</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="WaitingOrder"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      :rowKey="
        (record, index) => {
          return record.cmId;
        }
      "
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '2200px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <!--  @change="changeTable" -->
      <span slot="cmBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span>
      <!-- 材料编号 -->
      <span slot="cmMOnly" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ record.tmaterial.mNumber }}
          </template>
          {{ record.tmaterial.mNumber }}
        </a-tooltip>
      </span>
      <!-- 材料类别 -->
      <span slot="cmMId" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ record.tmaterial.tmaterialType.mtName }}
          </template>
          {{ record.tmaterial.tmaterialType.mtName }}
        </a-tooltip>
      </span>
      <!-- 材料名称 -->
      <span slot="cmCreateTime" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ record.tmaterial.mName }}
          </template>
          {{ record.tmaterial.mName }}
        </a-tooltip>
      </span>

      <!-- 状态 -->
      <span slot="cmMState" slot-scope="text">
        <div>{{ text | CmMState }}</div>
      </span>
      <!-- 需要下单日期 -->
      <span slot="cmEarlyReachTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }}~{{
            $Format(record.cmLateReachTime).slice(0, 10)
          }}
        </div>
      </span>
      <!-- 应下单日期 -->
      <span slot="cmEarlyOrderTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }}~{{
            $Format(record.cmLateOrderTime).slice(0, 10)
          }}
        </div>
      </span>
      <!-- 供应商 -->
      <span slot="cmSName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <!-- 供应商是否变更 -->
      <span slot="cmSChange" slot-scope="text">
        <div :class="text == '1' ? 'red' : ''">
          {{ text == 0 ? "否" : "是" }}
        </div>
      </span>
      <!-- 收货地址 -->
      <span slot="tworksite" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text.wsName }}
          </template>
          {{ text.wsName }}
        </a-tooltip>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          v-if="
            isShowBtns.indexOf('WaitingOrder-/api/material/selectById') > -1
          "
          @click="showEdit(record, '变更供应商', 'Edit')"
          >变更供应商</a
        >
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf(
              'WaitingOrder-/api/constructionMaterial/addOrder'
            ) > -1
          "
          @click="GoToOrderMaterials(record, '确认下单物料', 'tableBtn')"
          >下单</a
        >

        <!--    v-if="
            record.cmSChange == 1 ||
            record.cmQuantity - record.cmOrderQuantity < 0
          " -->
        <!-- <a class="editBtn" @click="GoToAlreadyOrder(record)">退货</a> -->
        <a
          class="editBtn"
          v-if="
            record.cmSChange == 1 ||
            record.cmQuantity - record.cmOrderQuantity < 0
          "
          @click="showModal(record)"
          >退货</a
        >
      </span>
    </s-table>
    <a-modal v-model="modelvisible" @ok="handleOk" :width="420">
      <p>点击确定将跳转到此批物料所属的订单，然后点击详情进行退货操作</p>
    </a-modal>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <edit @onClose="onClose" :record="record" />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import { GetConstructionMaterialList } from "@/api/apiJF/material";
import { GetCompanies } from "@/api/device";
import edit from "./edit.vue";
// 搜索条件
const queryItems = [
  {
    label: "应下单日期",
    value: "cmOrderTime",
    type: "dateRange",
    range: ["cmOrderStartTime", "cmOrderEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "分公司",
    value: "cmBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },
  {
    label: "收货地址",
    value: "cmWsName",
    type: "input",
    defaultValue: "",
  },

  {
    label: "材料类别",
    value: "cmMtName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "材料名称",
    value: "cmMName",
    type: "input",
    defaultValue: "",
  },

  {
    label: "状态",
    value: "cmMState",
    type: "select",
    selectOption: "cmMStateList",
  },
];

export default {
  name: "WaitingOrder",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    edit,
  },
  data() {
    this.queryItems = queryItems;
    // this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      visibleDrawer: false,
      // 查询参数
      queryParam: {
        cmOrderStartTime: "",
        cmOrderEndTime: "",
        cmBcIds: "",
        cmWsName: "",
        cmMtName: "",
        cmMName: "",
        cmMState: "1", //待下单
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      //   pageNo: 1,
      //   pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: "材料编号",
          width: "100px",
          dataIndex: "cmMOnly",
          scopedSlots: { customRender: "cmMOnly" },
          ellipsis: true,
          align: "center",
        },
        {
          title: "材料类别",
          width: "150px",
          dataIndex: "cmMId",
          scopedSlots: { customRender: "cmMId" },
        },
        {
          title: "材料名称",
          width: "150px",
          scopedSlots: { customRender: "cmCreateTime" },
          dataIndex: "cmCreateTime",
          // align: "center",
          ellipsis: true,
        },
        {
          title: "型号",
          width: "100px",
          dataIndex: "tmaterial.mModel",
          align: "center",
        },
        {
          title: "单位",
          width: "90px",
          dataIndex: "tmaterial.mUnit",
          align: "center",
        },
        {
          title: "单价(元)",
          width: "90px",
          dataIndex: "tmaterial.mSalesPrice",
          align: "center",
        },
        {
          title: "需求数量",
          width: "90px",
          dataIndex: "cmQuantity",
          align: "center",
        },
        {
          title: "已下单数量",
          width: "90px",
          dataIndex: "cmOrderQuantity",
          align: "center",
        },
        {
          title: "待下单数量",
          width: "90px",
          dataIndex: "cmPId",
          customRender: (text, record) => {
            return record.cmQuantity - record.cmOrderQuantity;
          },
          align: "center",
        },
        {
          title: "状态",
          width: "90px",
          dataIndex: "cmMState",
          scopedSlots: { customRender: "cmMState" },
          align: "center",
        },
        {
          title: "需要到达日期",
          width: "200px",
          scopedSlots: { customRender: "cmEarlyReachTime" },
          dataIndex: "cmEarlyReachTime",
          align: "center",
        },
        {
          title: "应下单日期",
          width: "200px",
          scopedSlots: { customRender: "cmEarlyOrderTime" },
          dataIndex: "cmEarlyOrderTime",
          align: "center",
        },
        {
          title: "供应商",
          width: "200px",
          dataIndex: "cmSName",
          // align: "center",
          scopedSlots: { customRender: "cmSName" },
          ellipsis: true,
        },
        {
          title: "供应商是否变更",
          width: "100px",
          dataIndex: "cmSChange",
          align: "center",
          scopedSlots: { customRender: "cmSChange" },
          // ellipsis: true,
        },
        {
          title: "收货地址",
          // width: "100px",
          dataIndex: "tworksite",
          // align: "center",
          scopedSlots: { customRender: "tworksite" },
          ellipsis: true,
        },
        {
          title: "分公司",
          width: "150px",
          dataIndex: "cmBcId",
          // align: "center",
          scopedSlots: { customRender: "cmBcId" },
          ellipsis: true,
        },
        {
          title: "操作",
          width: "160px",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          dataIndex: "action",
          align: "center",
        },
      ],
      modelvisible: false,
      AlreadyOrderRecord: {},
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = this.columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.cmBcIds == "") {
            this.queryParam.cmBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("WaitingOrderColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = this.columns.filter((el) =>
        value.includes(el.dataIndex)
      );
      this.tableColumns = selectColumns.length ? selectColumns : this.columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      if (this.queryParam.cmBcIds != "") {
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };

        return GetConstructionMaterialList(params).then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          // console.log("物料清单", res);
          return res.data;
        });
      }
    },
    // 状态筛选
    onFilterState(value) {
      console.log("onFilterState", value);
    },
    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }

      if (
        this.queryParam.cmBcIds == "" ||
        this.queryParam.cmBcIds == undefined
      ) {
        this.queryParam.cmBcIds = this.CompanyListid.toString();
      }
      // this.queryParam.cmMState = "1";

      this.$refs.table.refresh(true);
    },

    // 下单
    GoToOrderMaterials(record, name, type) {
      console.log(name, record);
      let tConstructionMaterials = "";
      if (type == "allBtn") {
        tConstructionMaterials = JSON.stringify(this.selectedRows);
      } else if (type == "tableBtn") {
        let list = [];
        list.push(record);
        tConstructionMaterials = JSON.stringify(list);
      }

      //   console.log("tConstructionMaterials", tConstructionMaterials);
      this.$router.push({
        // name: "OrderMaterials",
        // params: { tConstructionMaterials },
        path: "/OrderMaterials",
        query: { tConstructionMaterials },
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    showModal(record) {
      // console.log("record", record);
      this.AlreadyOrderRecord = record;
      this.modelvisible = true;
    },
    handleOk(e) {
      // console.log(e);
      this.modelvisible = false;
      this.GoToAlreadyOrder(this.AlreadyOrderRecord);
    },
    // 退货 -- 跳转到订单列表页面
    GoToAlreadyOrder(record) {
      console.log("退货", record);
      this.$router.push({
        name: "AlreadyOrder",
        params: {
          // record.tmaterial.mName,//收货人
          receivingAddress: record.tworksite.wsName,
        },
      });
    },
    // 修改供应商
    showEdit(record, title, type) {
      this.DrawerTitle = title;
      this.visibleDrawer = true;
      this.record = JSON.stringify(record);
    },
    onClose() {
      this.visibleDrawer = false;
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
.red {
  color: red;
}
.editBtn {
  margin-left: 10px;
}
</style>